import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
// import PreviewCompatibleImage from './PreviewCompatibleImage';

import { Card, CardImage, CardContent, CardFooter, Column, Columns, Section } from './bulma';

export default function BlogHeadlines({ box }) {
	const data = useStaticQuery(DIGEST_ROLL_QUERY);
	const { edges: posts } = data.allWordpressPost;

	return (
		<Columns multiline>
			{posts.map(({ node: post }) => (
				<div key={post.id} className="column card">
					{/* <CardImage>
							<figure class="image is-4by3">
								<img src="https://bulma.io/images/placeholders/1280x960.png" alt="Placeholder image" />
							</figure>
						</CardImage> */}
					<CardContent>
						<div class="media">
							{/* <div class="media-left">
									<figure class="image is-48x48">
										<img
											src="https://bulma.io/images/placeholders/96x96.png"
											alt="Placeholder image"
										/>
									</figure>
								</div> */}
							<div class="media-content">
								<p class="title is-4">{post.title.replace('&nbsp;', ' ').replace('&#8211;', ' - ')}</p>
								<p class="subtitle is-6">
									@finhub <a href="#">#fintech</a> <a href="#">#startups</a>
								</p>
								{/* <time datetime={post.date}>{post.date}</time> */}
							</div>
						</div>
					</CardContent>

					<br />
					<Link to={`/fintech/${post.slug}`} className="button is-dark">
						Read more >
					</Link>
				</div>
			))}
		</Columns>
	);
}

const DIGEST_ROLL_QUERY = graphql`
	query DigestRollQuery {
		allWordpressPost(filter: { categories: { elemMatch: { name: { eq: "cent-digest" } } } }) {
			edges {
				node {
					id
					slug
					excerpt
					content
					date
					title
					# jetpack_featured_media_url {
					# 	localFile {
					# 		childImageSharp {
					# 			fixed(width: 500, height: 300) {
					# 				...GatsbyImageSharpFixed_withWebp
					# 			}
					# 		}
					# 	}
					# }
					categories {
						id
						name
					}
				}
			}
		}
	}
`;
